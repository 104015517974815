/* src/style.css */



  @import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Outfit:wght@100..900&family=Quicksand:wght@300..700&display=swap');

  @font-face {
    font-family: "Outfit", sans-serif;
    font-weight: 500;
    font-style: normal;
    }
    
    @font-face {
      font-family: "Outfit", sans-serif;
    font-weight: 700;
    font-style: normal;
    } 

body {
    font-family: "Outfit", sans-serif !important;
    margin: 0;
    padding: 0;
    color: #404041;
  }

 body h1 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 4em;
    line-height: 1.1;
    font-family: "Outfit", sans-serif;
    font-weight:600;
  }

  b

  .root {
    overflow: hidden;
  }

 

  h2 {
    font-size: 2em;
    line-height: 1.2;
    font-weight: 400;
    color: #000;
  }


   p ,  a, button {
    font-size: 1.1em;
  }

  .space-small {
    padding-bottom: 1em;
  }
  
  .accordion-item .accordion-button, .accordion-item .accordion-body {
    padding: 30px 20px 30px 30px;
    border: none !important;
  }

  .accordion-item .accordion-body {
    padding-top: 0px;
  }

  /* body h1 , body h2 , body h3 , body h4 , body h5 , body h6 {
    color: #404041;
  } */

  a:hover {
    background: linear-gradient(to right,#5E1675,#5E1675 25%,#c5109a 51%,#EE4266 79%, #EE4266);
    background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }

  /* .font-22 {
    font-size: 22px;
  } */
  
  .font-35 {
    font-size: 35px;
    line-height: 1.4em;
  }

  .font-36 {
    font-size: 36px;
  }

  .font-43 {
    font-size: 43px;
  }
  
  .container {
    /* max-width: 1600px; */
    margin: 0 auto;
  }

  .bg-blue-dark-image {
    background-image: url('./images/Glides.png');
    background-size: 100% 100%;
  }

  .callto-section {
    background: linear-gradient(to right,#5E1675,#5E1675 25%,#c5109a 51%,#EE4266 79%, #EE4266);
  }

  .gradient-bg {
    background: linear-gradient(to right,#5E1675,#5E1675 25%,#c5109a 51%,#EE4266 79%, #EE4266);
  }

  .hero-section-bar {
    background: rgb(255,255,255);
background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgba(197, 16, 154, 0.1) 100%);
  }
  

  /* *{
    font-family: 'Poppins', sans-serif !important;
    } */

  body .container, body .container-lg, body .container-md, body .container-sm, body .container-xl, body .container-xxl {
    max-width: 1920px
    ;
    padding-top: 0px;
  padding-bottom: 0px;
  }

  .branding-logo {
    width: 9.8em;
  height: 5.625em;
  border: 1px solid var(--white);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255,255,255,.7);
  border-radius: 1em;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 2px 80px rgba(59,42,130,.06);
  }

  .brands-list {
  width: 87.5em;
  grid-column-gap: 1.25em;
  grid-row-gap: 1.25em;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  }

  body .container {
    z-index: 90;
    width: 100%;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10.625em;
    padding-right: 10.625em;
    position: relative;
  }

  .branding-1 , p{
    color: #404041;
  }

  .branding-2{
    color : #ff914d;
  }
  
  .bg-branding-1{
    background-color: #404041;
  }

  .bg-branding-2{
    background-color: #ff914d;
  }

  .font-weight-bold , strong{
    font-weight: 600;
  }

  .font-weight-bold-600 {
    font-weight: 600;
  }

  .font-weight-bold-700 {
    font-weight: 700;
  }

  .section-gray {
        background-color: #f8f8f8;
  }

  .bg-blue-dark {
    background-color: #1a0820;
  }

  .animated-button-2.btn-outline-primary.mx-2.active.animated-button {
    color: #fff;
  }

  .button-gradient-border {
    border-width: 1px; /* Set the border width as needed */
    border-image: linear-gradient(to right, #5E1675, #5E1675 25%, #c5109a 51%, #EE4266 79%, #EE4266);
    border-image-slice: 1; /* Ensures the entire border is filled with the gradient */
    border-style: solid;
  }

  .button-branding-1{
    background-color: #ff914d;
    padding: 10px 25px;
    color:#fff;
    border-radius: 35px;
    text-decoration: none;
  }

  .button-branding-tabs.active {
    background-color: #ff914d;
  }

  .button-branding-tabs{
    background-color: #d9d9d9;
    padding: 10px 25px;
    color:#fff;
    border-radius: 35px;
    text-decoration: none;
    font-weight: 900;
  }

  .btn-primary{
    background-color: #ff914d !important;
    padding: 10px 25px;
    color:#fff;
    border-radius: 35px !important;
    text-decoration: none !important;
  }

  .btn-primary:hover{
    background-color: #404041 !important;
  }

  .button-branding-1:hover{
    background-color: #404041;
  }

  .button-branding-2:hover{
    background-color: #ff914d;
    color:#fff;
  }

  .border-orange {
border: 1px solid #ff914d;
  }

  .button-branding-2{
    background-color: #fff;
    padding: 10px 25px;
    color:#ff914d;
    border-radius: 35px;
    border: 1px solid #ff914d;
    text-decoration: none;
  }
  .navbar {
    z-index: 999;
  }

  .dropdown-menu {
    padding: 0px;
    margin: 0px;
    color: #101F2F;
    border: none;
    border-radius: 0px;
    }
    .dropdown-item {
    display: block;
    width: 100%;
    padding: .75rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #101F2F;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #eee;
    }

    @media only screen and (min-width: 992px) {
      .dropdown:hover>.dropdown-menu {
        display: block;
      }    
    }

    .dropdown .dropdown-menu {
      border-radius: 15px;
      padding: 0;
    }
    .dropdown-menu .dropdown-item {
      padding: 15px 20px;
      border-bottom: 1px solid #e1e1e1;
    }
    .footer-section {
      padding: 60px 0px;
    }
    .footer-section p, .footer-section div, .footer-section a, .footer-section li {
      font-size: 1em;
    }
  /* Add more styles as needed */
  

  /* FAQ section */

  accordion-button:not(.collapsed)  {
    display: none;
  }

  .accordion-button {
    background-color: unset !important;
  }

  .accordion-button:focus {
    background-color: unset !important;
    border: unset !important;
    box-shadow: unset !important;
  }

  .accordion-item:first-of-type {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
  }

  .accordion-item:last-of-type {
    border-bottom-right-radius: 15px !important;
    border-bottom-left-radius: 15px !important;
  }

  .accordion-body {
    padding: 5px 27px;
  }

  .accordion-header button {
    font-weight: 500;
    color: #000 !important;
  }

  /* .container h3.mb-3 {
    font-size: 44px !important;
    margin-bottom: 25px !important;
  } */

  /* .home .container h3 {
    font-size: 42px !important;
  } */

  .title-text {
    font-size: 43px !important;
  }

  
.read-more::after {
  content: ">";

  margin-left: 4px;

  font-size: 16px;

}

.listing-items li {
  position: relative;
  list-style: none;
  margin-bottom: 10px;
}

.listing-items li::before {
  content: "";
  background: url('./images/check-mark.png') no-repeat center center; /* Replace 'your-icon.png' with your actual icon path */
  background-size: contain;
  width: 16px; /* Set the width of your icon */
  height: 16px; /* Set the height of your icon */
  display: inline-block;
  position: absolute;
  left: -20px;
  margin-right: 10px;
  top: 4px;
}

.terms-content p {
  position: relative;
}

.terms-content li p::before {
  content: "\26AC";
  list-style: decimal;
  position: absolute;
  margin-left: -10px;
}

.gradient-class {
background: linear-gradient(to right,#5E1675,#5E1675 25%,#c5109a 51%,#EE4266 79%, #EE4266);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.gradient2-class {
  background: linear-gradient(270deg,rgb(251, 200, 34)31%,#ff286f 69%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  }

  .gradient2-bg {
    background: linear-gradient(to bottom,rgb(255, 48, 103),rgb(80, 20, 173));
  }

  .gradient3-bg {
    background: linear-gradient(to bottom,#fbc822,#fa6328);
  }

.animated-button {
 background: linear-gradient(to right,#5E1675,#5E1675 25%,#c5109a 51%,#EE4266 79%, #EE4266);
    padding: 10px 25px;
    color:#fff;
    border-radius: 35px;
    text-decoration: none;
    position: relative;
    display: inline-block;
    font-weight: 600;
    height: 3.125em;
  border: unset;
  border-radius: 0.9em !important;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 15px 45px rgba(255,48,103,.35);
  grid-column-gap: .625em;
  grid-row-gap: .625em;
  color: #fff;
  border-radius: 1.25em;
  align-items: center;
  padding: 1.188em 1.3875em;
    padding-top: 1.188em;
    padding-bottom: 1.188em;
  padding-top: 1.1875em;
  padding-bottom: 1.1875em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  transition: all .2s;
}

.animated-button-2 {
    background-color: #fff;
    padding: 10px 25px;
    color: #5E1675;
    border-radius: 35px;
    border: 1px solid #ff914d;
    text-decoration: none;
    position: relative;
    font-weight: 600;
    height: 3.125em;
  border: unset;
  border-radius: 0.9em !important;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 15px 45px rgba(255,48,103,.35);
  grid-column-gap: .625em;
  grid-row-gap: .625em;
  border-radius: 1.25em;
  align-items: center;
  padding: 1.188em 1.3875em;
    padding-top: 1.188em;
    padding-bottom: 1.188em;
  padding-top: 1.1875em;
  padding-bottom: 1.1875em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  transition: all .2s;
}

.callto-section .animated-button-2 {
  box-shadow: 0 15px 45px rgba(26, 8, 32, 0.4);
}

.animated-button:hover {
  background-color: #fff;
  background: linear-gradient(to right,#5E1675,#5E1675 25%,#c5109a 51%,#EE4266 79%, #EE4266);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}



a.animated-button-2:hover {
  background: #fff !important;
  background: none;
  background-clip: unset;
  -webkit-background-clip: text;
  -webkit-text-fill-color: unset;
  box-shadow:none;
}

a.animated-button-2:hover span {
  background: linear-gradient(to right,#5E1675,#5E1675 25%,#c5109a 51%,#EE4266 79%, #EE4266);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  position: relative;
}

.display-mobile {
  display: none;
}

.stripe {
  display: block;
  width: 100%;
  height: 2px;
  margin: 0.3em 0;
  transition: background-color 0.3s ease;
  border-radius: 50px;
  background: linear-gradient(to right,#5E1675,#5E1675 25%,#c5109a 51%,#EE4266 79%, #EE4266);
}

/* Add styles for the toggled state if needed */
.navbar-toggler.active .stripe:nth-child(1) {
  transform: rotate(-45deg) translate(-3px, 3px);
}

.navbar-toggler.active .stripe:nth-child(2) {
  opacity: 0;
}

.navbar-toggler.active .stripe:nth-child(3) {
  transform: rotate(45deg) translate(-3px, -3px);
}


/* .animated-button-2.bg-transparent:hover {
  border: 1px solid #FF0000 !important;
} */

/* .animated-button:before , .animated-button-2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 35px;
  height: 100%;
  background: linear-gradient(to right, #FF0000 0%, #FF914D 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.animated-button:hover:before , .animated-button-2:hover:before  {
  opacity: 1;
} */

.accordion-button {
  padding-left: 15px;
  font-size: 22px;
}

/* .accordion-body p {
  font-size: 22px;
} */

.footer-bg {
  background-color: #1a0820;
}

.accordion {
  width: 60%;
  margin: 0 auto;
  box-shadow: 0px 0px 30px #00000017;
  border-radius: 30px;
}

.accordion-item h2 {
  margin: 0px !important;
  border: none;
}

.copyrights {
  background-color: #0f0214;
}

.accordion p {
  margin: 0px;
}

.footer-bg input::placeholder {
  color: #fff; /* Set your desired placeholder color */
   padding-right: 20px; /* Space for the icon */
  /* background-image: url('./images/right-arrow-icons.png'); 
  background-repeat: no-repeat;
  background-position: right center; */
}

.title-font{
  font-size: 43px;
}

.title-font span {
  font-size: 56px;
}

.most-popular {
  position: relative;
  /* border: 1px solid #FF914D; */
    border-width: 2px; /* Set the border width as needed */
    border-image: linear-gradient(to right, #5E1675, #5E1675 25%, #c5109a 51%, #EE4266 79%, #EE4266);
    border-image-slice: 1; /* Ensures the entire border is filled with the gradient */
    border-style: solid;
}

.animated-button.btn , .animated-button-2 {
  font-weight: 600;
}

/* .animated-button {
  position: relative; 
  display: inline-block;
  overflow: hidden; 
}

.animated-button:hover {
  background: linear-gradient(to right, #5E1675, #5E1675 25%, #c5109a 51%, #EE4266 79%, #EE4266);
  background-color: rgba(0, 0, 0, 0);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-image: linear-gradient(to right, #5E1675, #5E1675 25%, #c5109a 51%, #EE4266 79%, #EE4266) 1;
  border-image-slice: 1;
  border-style: solid;
  border-radius: 35px;
} */

.non-active-img {
  display: none;
}
.animated-button:hover .active-img {
  display: none;
}
.animated-button:hover .non-active-img {
  display: block;
}

.most-popular:after {
  content: "";
  background-image: url('./images/popular.png'); /* Replace with your image path */
  background-repeat: no-repeat;
  background-position: top;
  background-size: 150px;
  top: -18px;
  right: -30%;
  z-index: 99;
  bottom: 90%;
  position: absolute;
  left: 0;
}

body .py-5 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.padding-top {
  padding-top: 80px !important;
}

.padding-bottom {
  padding-bottom: 80px !important;
}

.fs-5 {
  font-size: 22px;
}

.navbar-toggler-icon {
  /* filter: brightness(100); */
}

.navbar-toggler {
  border: none !important;
}

/* .navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: #000;
  background-color: #f00;
  background: linear-gradient(to right, #FF0000 0%, #FF914D 70%);
  background-color: rgba(0, 0, 0, 0);
  border: var(--bs-border-width) solid rgba(255, 255, 255, 0.1);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  border: none !important;
} */

.purple-bg {
  background-color: #1a0820;
}
body .py-8 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}
.showcase-section {
  background-image: url('./images/HG-WingsBG.svg');
  background-position: bottom -200px center;
  background-size: 180%;
  background-repeat: no-repeat;
}
.banner-item-rotate {
  z-index: 100;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  justify-content: center;
  align-items: center;
  margin-top: 6em;
  display: flex;
  position: relative;
  transform: perspective(5000px)rotate(0);
}
.banner-images {
  width: 60.375em;
  padding-top: 30%;
  position: relative;
}
.image-small.flip {
  border-radius: .9375em;
  overflow: visible;
  box-shadow: 14px 14px 60px rgba(59,42,130,.06);
}
.image-small {
  width: 12.0625em;
  transform-style: preserve-3d;
  position: absolute;
  top: 3%;
  bottom: auto;
  left: 4.2em;
  right: auto;
  transform: translateZ(-50px);
}

.image-medium.flip {
  border-radius: 1.25em;
  box-shadow: 14px 14px 60px rgba(59,42,130,.06);
}
.image-medium {
  z-index: 5;
  width: 14.375em;
  transform-style: preserve-3d;
  position: absolute;
  top: -3%;
  bottom: auto;
  left: 12.1em;
  right: auto;
  transform: translateZ(18px);
}
.image-big.flip {
  z-index: 20;
  transform-style: preserve-3d;
  border-radius: 1.5625em;
  transform: translateZ(40px);
  box-shadow: 14px 14px 60px rgba(59,42,130,.06);
}
.image-big {
  z-index: 10;
  width: 18.75em;
  position: absolute;
  top: -15%;
  bottom: auto;
  left: auto;
  right: auto;
}
.image-medium.is-right.flip {
  border-radius: 1.25em;
  box-shadow: 14px 14px 60px rgba(59,42,130,.06);
}
.image-medium.is-right {
  left: auto;
  right: 12.1em;
}
.image-small.is-right {
  left: auto;
  right: 4.2em;
}

.showcase-btm-ctn {
  width: 800px;
  height: 800px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 80px;
  position: relative;
}
.showcase-btm-ctn::after {
  content: '';
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  left: -84px;
  right: 0;
  top: 50%;
  bottom: 0;
  border-radius: 30px;
  height: 840px;
  z-index: -1;
  width: 970px;
  margin: 0 auto;
}
.showcase-btm-left, .showcase-btm-right {
  border-radius: 30px;
  padding: 30px 40px;
}
.showcase-btm-left img, .showcase-btm-right img {
  width: auto;
  max-height: 100px;
}

.blog-content img {
  width: 100%;
}


/***Media CSS***/

@media only screen and (max-width: 1210px) and (min-width: 1200px){
#navbarNav .responsive-menu-data .menu-item.text-black {
  padding: 0px 8px !important;
}
}

@media only screen and (min-width: 1366px) {
.w-70 {
  width: 70em;
}
.w-63 {
  width: 63em;
}

.w-52 {
  width: 52em;
}
}


@media only screen and (max-width: 1210px) {
  .navbar-toggler {
    display: block;
  }

  .navbar-collapse {
    display: none;
  }


  body .container {
    padding-left: 2em;
    padding-right: 2em;
  }

  .navbar-collapse.show {
    display: block;
  }

  body .fixed-top {
    -webkit-backdrop-filter: blur(0px) !important;
    backdrop-filter: unset !important;
    background-color: #fff !important;
  }
}

@media only screen and (max-width: 1266px) and (min-width: 1210px){
  #navbarNav .responsive-menu-data .menu-item.text-black {
    padding: 0px 10px !important;
  }
  }

@media only screen and (max-width: 1375px) and (min-width:1266px) {
#navbarNav .responsive-menu-data .menu-item.text-black {
  padding: 0px 15px !important;
}
}

@media only screen and (max-width: 1654px) {
.navbar .container {
  padding: 0px 30px;
}
}

.fixed-top {
  z-index: 99999;
  box-shadow: 0px 0px 30px #00000017;
}

@media only screen and (max-width: 1000px) {
.w-63 , .w-52 {
  width: 100%;
}
body .container {
  z-index: 90;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
.accordion {
  width: 100%;
}
}


@media only screen and (max-width: 767px) {
.main-section-form .section-gray {
  width: 100% !important;
  padding: 30px 15px !important;
}

.slick-list {
  height: 400px;
}
.showcase-btm-box {
  width: 100%;
  padding-bottom: 0px !important;
}
.showcase-btm-box .col-md-2 {
  padding: 0px;
  margin-bottom: 15px;
  margin-top: 15px;
}
.showcase-btm-left img, .showcase-btm-right img {
  width: auto;
  max-height: 80px;
  margin-bottom: 15px;
}

.showcase-btm-ctn {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  position: relative;
}
.showcase-btm-left, .showcase-btm-right {
  border-radius: 20px;
  padding: 30px 40px;
}
.showcase-btm-box .col-md-5 {
  padding: 0px;
}
}

.mobile-menu-container ul {
  padding: 30px 0px;
}

.mobile-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff; /* Set your background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add a box shadow for better visibility */
  /* transform: translateX(-100%); */
  transition: transform 0.3s ease-in-out;
  z-index: 1000; /* Make sure the mobile menu is on top of other elements */
  padding: 30px 20px;
}

.animated-button:hover img {
  background-color: #fff;
  background: linear-gradient(to right,#5E1675,#5E1675 25%,#c5109a 51%,#EE4266 79%, #EE4266);
    background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 479px) {
  .contact-form {
    width: 100% !important;
    padding: 20px !important;
    margin-top: 30% !important;
    margin-bottom: 15% !important;
  }
  .signin-page .css-2jxnfm {
    grid-template-columns: 100% !important;
  }
  .sticky-heading {
    position: relative !important;
  }
  section , .container{
    z-index: 9;
    position: relative;
  }
  body .home .scroll-data .col-md-5 {
    padding: 0px;
  }

  .sticky-content_informations.is-home {
    padding-left: 0px !important;
    margin-top: 30px;
  }
  
  .sticky-content__feature {
    padding: 10px !important;
  }

  .sticky-content__feature {
    opacity: 1;
    transform: translateX(0px) !important;
  }

  body .home .scroll-data {
    padding-bottom: 0px !important;
  }

  .terms-section .w-75 {
    width: 100% !important;
    padding: 20px 10px !important;
  }

  body .fixed-top {
    backdrop-filter: unset !important;
  }
  
  .showcase-btm-ctn::after {
    left: -15px;
    height: 920px;
  z-index: -1;
  width: 109%;
  }
  .slick-list {
    height: 400px;
  }
  .showcase-btm-box {
    width: 100%;
    padding-bottom: 0px !important;
  }
  .showcase-btm-box .col-md-2 {
    padding: 0px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .showcase-btm-left img, .showcase-btm-right img {
    width: auto;
    max-height: 80px;
    margin-bottom: 15px;
  }
  
  .showcase-btm-ctn {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    position: relative;
  }
  .quick-access .row {
    margin-bottom: 20px !important;
  }
  .about .row * {
    padding: 0px;
  }
  .animated-button , .animated-button-2{
    height: 2.8em;
  }
  .text-container .logo-brands.mb-5 {
    width: 100%;
  }
  body h1 {
    font-size: 2.5em;
  }
  .branding-logo {
    width: 5em;
  }
  .hero-section-bar .w-75, .hero-section-bar .w-50 {
    width: 100% !important;
  }
  .display-mobile {
    display: block;
  }
  .animated-button , .animated-button-2 {
    padding: 10px 10px;
  }
  .display-desktop {
    display: none;
  }
  .main-section-form .row .col-md-3 {
    display: none;
  }
  .tabs .d-flex.justify-content-center {
    display: block !important;
    text-align: center;
  }
  .tab-content .section-gray {
    padding: 25px !important;
  }
  #root {
    overflow: hidden;
  }
  .about-us-title .w-50 {
    width: 100% !important;
  }
  .font-36 {
    font-size: 26px;
  }
  .recent-blog-banner .row .col-md-4 , .recent-blog-banner .row .col-md-8 p , .recent-blog-banner .row .col-md-8{
    padding: 0px;
  }
  .recent-blog-banner .row .col-md-8 p img {
    width: 16px;
  }
  .container .recent-blog-banner {
    padding: 20px !important;
  }
  .service-section .col-md-5 {
    padding: 0px !important;
  }
  .quick-access .row {
    display: flex;
  }
  .quick-access .row .col-md-3 {
    width: 30%;
  }
  .quick-access .row .col-md-9 {
    width: 70%;
  }
  .row .links-tab {
    margin-bottom: 0px !important;
  }
  .row .links-tab a {
    margin-bottom: 0px;
  }
  .quick-access .row .col-md-9 p {
    font-size: 14px;
  }
  .quick-access .row {
    margin: 20px 0px !important;
  }
  .quick-access .row .section-gray {
    max-width: 100% !important;
  }
  .container .quick-access {
    padding: 15px  !important;
  }
  .nner-content {
    width: 100% !important;
    padding: 0px 15px;
      padding-bottom: 0px;
  }
  .tab-content .col-md-4 {
    margin: 25px 0px;
  }
  .call-action-dark .row {
    padding: 0px 20px !important;
  }
  .call-action-dark .links-tab {
    float: unset !important;
    margin: 0px !important;
    padding: 0px !important;
  }
  .tabs button {
    margin: 10px 0px !important;
  }
  .title-text {
    font-size: 28px !important;
  }
  .service-section .w-75 {
    width: 100% !important;
  }
  .padding-top {
    padding-top: 60px !important;
  }
  .padding-bottom {
    padding-bottom: 60px !important;
  }
  .service-section .w-75 {
    width: 100% !important;
  }
  .service-section br {
    display: none;
  }
  .calling-data .bg-blue-dark-image {
    padding: 15px !important;
    margin-top: 40px;
  }
  .bg-blue-dark-image {
    background-size: cover;
  }
  .accordian-react-data.w-75 {
    width: 100% !important;
  }
  .copyrights .col-md-6 {
    text-align: center !important;
  }
  .callto-section {
    background-size: cover;
    background-position: right;
  }
  .logo-brand {
    width: 220px !important;
  }
  .copyrights .col-md-6 .row {
    display: block !important;
  }
  .font-35 {
    font-size: 24px;
  }
  .home .faqs-section {
    padding-top: 0px !important;
  }
  .calling-data .bg-blue-dark-image .p-4 {
    padding: 10px !important;
  }
  body .py-5 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .container .row {
    margin: 0px !important;
  }
  .container .row .col-md-6 {
    padding: 0px;
  }
  .links-tab.py-2.my-5 {
    margin-top: 0px !important;
  }
  .navbar .container {
    margin: 0px;
    max-width: 100% !important;
  }
  /* .navbar-toggler {
    padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
    font-size: var(--bs-navbar-toggler-font-size);
    line-height: 1;
    color: #000;
    background-color: #f00;
    background: linear-gradient(to right, #FF0000 0%, #FF914D 70%);
    border: var(--bs-border-width) solid rgba(255, 255, 255, 0.1);
    border-radius: var(--bs-navbar-toggler-border-radius);
    transition: var(--bs-navbar-toggler-transition);
  } */
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 100% !important;
    padding: 0px 30px !important;
  }
  
  .links-tab a {
    width: 100%;
    margin: 20px 0px;
      margin-left: 0px;
    display: block;
    margin-left: 0px !important;
    text-align: center;
  }
  body .my-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .row {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .animated-button , .animated-button-2 {
    width: 100%;
    margin: 15px 0px;
    text-align: center;
    display: block;
    margin-left: 0px !important;
  }
  .footer-section .row .col-md-2, .footer-section .row .col-md-3 {
    padding-left: 0px !important;
    margin-bottom: 20px;
  }
  .footer-section .row {
    padding: 0px !important;
  }

  

  .title-font{
    font-size: 32px;
  }
  
  .title-font span {
    font-size: 43px;
  }
  .nav-item a {
    font-size: 20px;
  }
}


.mobile-menu li {
  /* border-bottom: 1px solid #FFF1E9; */
  padding: 15px 0px;
}

.mobile-menu li:last-child {
  border-bottom: none;
}

.collapse.navbar-collapse.show.bg-blue-dark.text-center {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: inline-block;
  padding-top: 200px;
}


.sticky-content_informations {
  /* width: 60%; */
  grid-column-gap: 4em;
  grid-row-gap: 4em;
  flex-direction: column;
  /* padding-left: 12.5em; */
  display: flex;
}

.sticky-content__feature {
  width: 100%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  text-align: left;
  border: 1px solid #fff;
  border-radius: 1.5625em;
  padding: 0 2.5125em 2.5125em;
    padding-right: 2.5125em;
  position: relative;
  box-shadow: 0px 0px 15px #00000017;
}

.sticky-content__feature.is-is {
  /* background-image: radial-gradient(circle at 0 0,#6c6f6f,#0c0d0d); */
  padding-right: 7.0125em;
}

.sticky-content__feature {
  opacity: 0;
  transform: translateX(200px); /* Initial position off-screen */

  &.visible {
    opacity: 1;
    transform: translateX(0); /* Move to the original position */
    transition: opacity 2s ease, transform 1s ease;
  }
}

.sticky-content__feature-icon-wrapper {
  width: 4.875em;
  height: 4.875em;
  background-color: rgba(255,255,255,.3);
  border: 1px solid #fff;
  border-radius: 1.25em;
  padding: .625em;
  transform: translateY(-50%);
}
.contact-us .sticky-content__feature-icon-wrapper {
  transform: none;
}


/* #root {
  overflow-x: hidden;
} */

body .fixed-top {
  backdrop-filter: blur(50px);
  background-color: rgba(255,255,255,.8) !important;
}

.logo-white-wrapperr.is-red.with-shadow {
  border-radius: .9375em;
  padding: .9em;
  box-shadow: 0 15px 25px rgba(255,48,103,.35);
}
.logo-white-wrapperr.is-red {
  width: 100%;
  /* background-color: #5e1675; */
  background: linear-gradient(to right,#5E1675,#5E1675 25%,#c5109a 51%,#EE4266 79%, #EE4266);;
  justify-content: space-between;
  margin-right: auto;
  padding-left: 1.1em;
  padding-right: 1.1em;
}

.sticky-content_informations.is-home {
  padding-left: 3rem;
}

.sticky-heading {
  width: 37%;
  height: 30em;
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
  top: 35%;
  /* transform: translateY(-50%); */
}

/* .contact-form {
  background: #fff;
  margin-top: 10%;
  margin-bottom: 5%;
  width: 50%;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px #000;
} */
.contact-form .form-control {
  border-radius: 0.5rem;
}
.contact-form h3 {
  margin-bottom: 1rem;
  text-align: center;
  color: #0062cc;
}
.contact-form {
  width: 60%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  text-align: left;
  border: 2px solid #fff;
  border-radius: 1.5625em;
  padding: 0 2.5125em 2.5125em;
    padding-right: 2.5125em;
  position: relative;
  padding: 50px;
  /* box-shadow: 0px 0px 15px #00000017; */
  margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 0px;
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: #ffffff57 !important;
  background-image: radial-gradient(circle at 50% 0,#fffc,#fff3 20%);
  border: 1px solid #fff;
  /* background-color: #ffffff57 !important;
  background-image: radial-gradient(circle at 50% 0,#fffc,#fff3 20%);
  border: 1px solid #fff; */
}

.contact-us .container.contactss {
  background: linear-gradient(270deg,#e0d1f5,#f6ecf3 48%,#e0d1f5) no-repeat 50%/cover;
  position: relative;
}



.contact-us .container.contactss::before {
  background-image: linear-gradient(270deg,#e9dbf500,#e0d1f5);

  content: "";

  height: 100%;

  left: 0;

  pointer-events: none;

  position: absolute;

  top: 0;

  width: 15%;

  z-index: 4;

}


.contact-us .container.contactss::after {
  background: linear-gradient(90deg,#e9dbf500,#e0d1f5);

    background-image: linear-gradient(90deg, rgba(233, 219, 245, 0), rgb(224, 209, 245));

  background-image: linear-gradient(90deg,#e9dbf500,#e0d1f5);

  content: "";

  height: 100%;

  pointer-events: none;

  position: absolute;

  right: 0;

  top: 0;

  width: 15%;

  z-index: 3;

}


.contact-form .form-group textarea { 

  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  -moz-box-align: center;
  display: grid !important;
  place-items: center !important;
  width: 100% !important;
  height: auto !important;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  color: rgb(73, 80, 87) !important;
  background-color: rgb(255, 255, 255) !important;
  background-clip: padding-box !important;
  border: 0.0625rem solid rgb(210, 214, 218);
  appearance: none !important;
  border-radius: 0.5rem;
  transition: box-shadow 150ms, border-color 150ms, padding 150ms !important;
  pointer-events: auto;
  margin-bottom: 20px;
  border: none !important;
  border-radius: 15px !important;

  padding: 15px 20px !important;
}
.contact-form .form-group input {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  -moz-box-align: center;
  display: grid !important;
  place-items: center !important;
  width: 100% !important;
  height: auto !important;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  color: rgb(73, 80, 87) !important;
  background-color: rgb(255, 255, 255) !important;
  background-clip: padding-box !important;
  border: 0.0625rem solid rgb(210, 214, 218);
  appearance: none !important;
  border-radius: 0.5rem;
  transition: box-shadow 150ms, border-color 150ms, padding 150ms !important;
  pointer-events: auto;
  margin-bottom: 20px;
  border: none !important;
  border-radius: 15px !important;
  height: 60px !important;
  max-height: 60px;
  padding: 15px 20px !important;
}


input::placeholder , textarea::placeholder {
  color: #5e5e5e !important;
  opacity: 0.42 !important;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

.animated-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}